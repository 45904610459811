/* iPhone 7 screen 375x559px */

@font-face {
    font-family: Arsenal;
    @import url("https://fonts.googleapis.com/css?family=Arsenal&display=swap");
}
body {
    /* letter-spacing: 0.95px; */
    font-family: "Arsenal", sans-serif;
    font-weight: 300;
    /* border: 2px solid blue; */
    /* max-width: 375px;
    max-height: 559px;
    min-width: 375px;
    min-height: 559px; */
    margin: 0 auto;
}

:root {
    --main-max-height: 94px;
    --main-min-height: 94px;
    --main-min-width: 334px;
    --main-max-width: 334px;
    --main-font: Regular 14px/21px Arsenal;
    --main-gradient-background: transparent linear-gradient(90deg, #7a8ce5 0%, #49aadb 100%) 0% 0%
        no-repeat padding-box;
    --main-gradient-button: transparent
        linear-gradient(90deg, #001dac 0%, #0153b5 48%, #0075af 100%) 0% 0% no-repeat padding-box;
    --main-gradient-font-color: #ffffff;
    --main-padding: 26px 20px;
    --main-radius: 17px;
    --main-light-gray: #000000b3;
    --main-blue: #519bf4;
    --main-blue-test: #49aadb;
    --main-blue-test-transparent: rgba(73, 170, 219, 0.2);
    --main-black: #000000;
    --main-link: #0084c7;
    --main-alert: #d12c68;
    --prep-step1: #7a8ce5;
    --prep-step1-opacity: rgba(122, 140, 229, 0.3);
    --prep-step2: #519bf4;
    --prep-step2-opacity: rgba(81, 154, 244, 0.3);
    --prep-step3: #49aadb;
    --prep-step3-opacity: rgba(73, 170, 219, 0.3);
}

a {
    color: var(--main-link);
}

button {
    border-radius: 12px;
    background: var(--main-gradient-button);
    border: none;
    color: var(--main-gradient-font-color);
    width: 240px;
    height: 54px;
    text-align: center;
    letter-spacing: 0.95px;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    opacity: 1;
    justify-content: center;
}

.content-button {
    color: var(--main-light-gray);
    font-size: 12px;
    letter-spacing: 0.51px;
    font-size: 10px;
}

.container {
    /* background: red;
    border: 2px solid green; */
    height: 100vh;
    /* width: 100vh; */

    justify-items: center;
    display: grid;
    /* grid-gap: 20px; */
    /* grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); */

    grid-template-areas:
        "header                 header                      header"
        "content-syndrome       content-syndrome            content-syndrome"
        "icon-container         icon-container              icon-container"
        "content-syndrome-more  content-syndrome-more       content-syndrome-more"
        "cart-icon-content      cart-icon-content           cart-icon-content"
        "button-examine         button-examine              button-examine"
        "content-button         content-button              content-button"
        "cart-content           cart-content                cart-content";
}

@media screen and (max-width: 600px) {
    .container {
        /* background-color: rgb(128, 123, 123); */
    }
}

.item {
    /* border: 1px solid black; */
}

.grid-content {
    min-width: var(--main-min-width);
    max-width: var(--main-max-width);
}

.header {
    background: var(--main-gradient-background);
    color: var(--main-gradient-font-color);

    font-size: 16px;
    grid-area: header;
    text-align: left;
    max-height: 120px;
    justify-content: center;
    display: flex;
    width: 100%;
}

.content-syndrome {
    grid-area: content-syndrome;
    text-align: left;
    padding: 30px 0px 14px 0px;
    min-width: var(--main-min-width);
    max-width: var(--main-max-width);
}

.icon-container {
    grid-area: icon-container;
    display: flex;
    min-width: var(--main-min-width);
    max-width: var(--main-max-width);

    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
}

.content-syndrome-more {
    grid-area: content-syndrome-more;
    display: flex;
    min-width: var(--main-min-width);
    max-width: var(--main-max-width);
    font-size: 14px;
    padding: 30px 0px 0px 0px;
}

.cart-icon-content {
    padding: 30px 0px 35px 0px;
    grid-area: cart-icon-content;
    text-align: left;
    min-width: var(--main-min-width);
    max-width: var(--main-max-width);
    font-size: 14px;
}

.button-examine {
    grid-area: button-examine;
}

.content-button {
    padding: 15px 0px 30px 0px;
    grid-area: content-button;
    text-align: center;
}

.cart-icon {
    /* margin: 0 20px; */
    display: flex;
    background-color: var(--main-blue);
    color: var(--main-gradient-font-color);
    border-radius: var(--main-radius);
    max-width: 94px;
    max-height: 94px;
    min-width: 94px;
    min-height: 94px;
    justify-content: center;
    align-items: flex-end;
    opacity: 1;
    font: Bold 13px/21px Arsenal;
    letter-spacing: 0.65px;
}

.cart-container {
    margin: 0px 20px 0px 20px;
    display: flex;
    border-radius: var(--main-radius);
    min-width: var(--main-min-width);
    max-width: var(--main-max-width);
    max-height: var(--main-max-height);
    min-height: var(--main-max-height);
}

.cart-desc {
    margin: 16px 16px 16px 16px;
    display: flex;
    text-align: left;
    align-items: center;
}

.cart-container .cart-icon {
    margin: 0 0;
}

.cart-horizontal {
    margin: 16px 0px 16px 0px;
    /* width: 100%; */
    /* justify-content: space-between; */
    display: flex;
    justify-content: center;
}

.cart-numbness {
    text-align: right;
}

.cart-lack-of-precision {
    text-align: right;
}

.cart-weakness {
    text-align: right;
}

.cart-content {
    grid-area: cart-content;
    text-align: left;
    font-size: 14px;
    padding: 30px 0px 30px 0px;
    min-width: var(--main-min-width);
    max-width: var(--main-max-width);
}

.modal-more-info {
    /* display: none; */
}

.numbness {
    background-image: url("../img/symptoms/numbness/numbness.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.lack-of-precision {
    background-image: url("../img/symptoms/lack-of-precision/lack-of-precision.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.weakness {
    background-image: url("../img/symptoms/weakness/weakness.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.numbness-small {
    background-image: url("../img/symptoms/numbness/numbness-small.png");
    background-repeat: no-repeat;
    background-size: 74px 74px;
    background-position: top;
}

.lack-of-precision-small {
    background-image: url("../img/symptoms/lack-of-precision/lack-of-precision-small.png");
    background-repeat: no-repeat;
    background-size: 74px 74px;
    background-position: top;
}

.weakness-small {
    background-image: url("../img/symptoms/weakness/weakness-small.png");
    background-repeat: no-repeat;
    background-size: 74px 74px;
    background-position: top;
}

.cart-icon.numbness {
    background-color: var(--prep-step1);
}
.cart-numbness .cart-container {
    background-color: var(--prep-step1-opacity);
}

.cart-icon.lack-of-precision {
    background-color: var(--prep-step2);
}
.cart-lack-of-precision .cart-container {
    background-color: var(--prep-step2-opacity);
}

.cart-icon.weakness {
    background-color: var(--prep-step3);
}
.cart-weakness .cart-container {
    background-color: var(--prep-step3-opacity);
}

/* modal */
/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 50px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 354px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -150px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes animatetop {
    from {
        top: -150px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    padding: 2px 16px;
    background: var(--main-gradient-background);
    color: white;
    font-size: 16px;
}

.modal-body {
    padding: 0px 16px;
}

.modal-footer {
    padding: 2px 16px;
    background: var(--main-gradient-background);
    color: white;
}

/* end modal */
